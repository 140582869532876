import {AfterViewInit, Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {debounceTime, distinctUntilChanged, Observable, Subject, Subscription, switchMap, tap} from "rxjs";
import {EnumOrderType} from "../../models/enums/EnumOrderType";
import {LocationService} from "../../services/location.service";
import {LocationModel} from "../../models/LocationModel";
import {addMinutes, fromUnixTime, getUnixTime, isAfter, isBefore} from "date-fns";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from "@angular/material/dialog";
import {DayAvailabilityWithTimes} from "../../models/DayAvailabilityWithTimes";
import {EnumWebsiteModules} from "../../models/enums/EnumWebsiteModules";
import {CartService} from "../../services/cart.service";
import {OrderCartModel} from "../../models/orders/OrderCartModel";
import {guidEmpty, isStringNotEmptyGuid, isStringNotEmptyOrWhitespace} from "../../utils/Utils";
import {WebsiteModel} from "../../models/WebsiteModel";
import {WebsiteService} from "../../services/website.service";
import { MatButtonToggleChange, MatButtonToggleGroup, MatButtonToggle } from "@angular/material/button-toggle";
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {AddressService} from "../../services/address.service";
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatAutocomplete } from "@angular/material/autocomplete";
import {isAuthenticatedValidator} from "../../validators/isAuthenticateValidator";
import {SessionStorageService} from "../../services/session-storage.service";
import {OrderGenericResponseModel} from "../../models/orders/OrderGenericResponseModel";
import {AddressDialogComponent} from "../address-dialog/address-dialog.component";
import {OrderUserAddressModel} from "../../models/orders/OrderUserAddressModel";
import { NgIf, NgFor, AsyncPipe, DatePipe } from '@angular/common';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { AuthComponent } from '../../components/auth/auth.component';
import { MatDivider } from '@angular/material/divider';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';

@Component({
    selector: 'app-order-type-chooser-dialog',
    templateUrl: './order-type-chooser-dialog.component.html',
    styleUrls: ['./order-type-chooser-dialog.component.scss'],
    standalone: true,
    imports: [NgIf, MatIconButton, MatDialogClose, MatIcon, CdkScrollable, MatDialogContent, FormsModule, ReactiveFormsModule, MatButtonToggleGroup, MatButtonToggle, AuthComponent, MatDivider, MatProgressSpinner, MatRadioGroup, MatRadioButton, MatFormField, MatLabel, MatInput, MatAutocompleteTrigger, MatAutocomplete, NgFor, MatOption, MatSelect, MatDialogActions, MatButton, AsyncPipe, DatePipe]
})
export class OrderTypeChooserDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() disableClose: boolean = false;
  public location$: Observable<LocationModel>;
  public EnumOrderType = EnumOrderType;
  public getUnixTime = getUnixTime;
  public addressHints$ = this.addressSvc.results$;
  public hintSubject = new Subject<string>();
  public addressHint: string = '';
  public location!: LocationModel;
  public website: WebsiteModel;
  public selectedTime: Nullable<number> = null;
  public readonly EnumWebsiteModules = EnumWebsiteModules;
  public addressForm!: FormGroup;
  public generalForm!: FormGroup;
  public isLoadingAddress: boolean = false;
  protected readonly isStringNotEmptyGuid = isStringNotEmptyGuid;
  protected readonly guidEmpty = guidEmpty;
  protected readonly isStringNotEmptyOrWhitespace = isStringNotEmptyOrWhitespace;
  private selectedDate: Nullable<DayAvailabilityWithTimes> = null;
  private dtNow = new Date();
  private sub = new Subscription();
  private cart$: Observable<OrderCartModel>;

  constructor(private locSvc: LocationService, public dialogRef: MatDialogRef<OrderTypeChooserDialogComponent>,
              private cartSvc: CartService, @Inject(MAT_DIALOG_DATA) public data: OrderCartModel,
              public websiteSvc: WebsiteService, private fb: FormBuilder, public addressSvc: AddressService, protected sessionSvc: SessionStorageService,
              private modal: MatDialog) {
    this.location$ = this.locSvc.location$;
    this.website = this.websiteSvc.getCurrentWebsite();
    this.cart$ = this.cartSvc.cart$;

  }

  ngOnInit() {
    this.generalForm = this.fb.group({
      orderType: [this.data.orderType, Validators.required],
      selectedAddressId: [this.data.selectedAddressId, Validators.required],
      addressHint: [''],
      isAsap: [this.data.isAsap, Validators.required],
      selectedDate: [this.selectedDate, Validators.required],
      selectedTime: [this.selectedTime, Validators.required],
      isGuestUser: [this.data.isGuestUser, {
        // validators: [Validators.required],
        asyncValidators: [isAuthenticatedValidator(this.sessionSvc)],
      }]
    });



    if (this.data.isGuestUser && this.sessionSvc.isAuth()) {
      this.data.isGuestUser = false;
    } else if (!this.data.isGuestUser && !this.sessionSvc.isAuth()) {
      this.data.isGuestUser = true;
    }

    this.cart$.subscribe(f => {
      if (f != null) {
        this.data = f;
        this.generalForm?.patchValue(this.data);
      }
    })

    this.sessionSvc.isAuth$.subscribe(f => {
      if (f) {
        this.generalForm.controls['isGuestUser'].setValue(false);
      }
    });




    this.addressForm = this.fb.group({
      address: ['', Validators.required]
    });
    this.addressSvc.clear();
    this.hintSubject.pipe(debounceTime(250), distinctUntilChanged(), switchMap(value => this.addressSvc.searchAddress(value))).subscribe(f => {
      // console.log('Hint after switchMap:', f);
    });


    this.generalForm.controls['orderType'].valueChanges.subscribe(f => {
      this.data.orderType = f;
      if (f === EnumOrderType.PickUp) {
        // this.generalForm.controls['selectedAddressId'].setValue('');
        this.generalForm.controls['selectedAddressId'].clearValidators();
        this.generalForm.controls['addressHint'].setValue('');
        this.generalForm.controls['addressHint'].clearValidators();
      } else if (f === EnumOrderType.Delivery) {
        this.generalForm.controls['selectedAddressId'].setValidators([Validators.required]);
      }
      this.generalForm.controls['addressHint'].updateValueAndValidity();
      this.generalForm.controls['selectedAddressId'].updateValueAndValidity();
    });

    this.generalForm.controls['selectedAddressId'].valueChanges.subscribe(f => {
      if (f === guidEmpty) {
        this.generalForm.controls['addressHint'].setValue('');
        if (this.generalForm.controls['orderType'].value === EnumOrderType.Delivery)
          this.generalForm.controls['addressHint'].setValidators([Validators.required]);
      } else {
        this.generalForm.controls['addressHint'].clearValidators();
      }
      this.generalForm.controls['addressHint'].updateValueAndValidity();
    });

    this.sub.add(
      this.location$.subscribe(data => {
        this.dtNow = new Date();
        this.location = data;
        this.location.processDays();
        this.processSelectedDate();
      }));
  }

  ngAfterViewInit() {
    // this.addressForm.controls['address'].valueChanges.pipe(
    //   debounceTime(250),
    //   tap(value => {
    //     this.addressSvc.searchAddress(value, this.data.id);
    //   })
    // ).subscribe();

    this.generalForm.controls['addressHint'].valueChanges.pipe(
      debounceTime(250),
      tap(value => {
        this.addressSvc.searchAddress(value);
      })
    ).subscribe();

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.hintSubject.complete();
  }

  getOrderType() {
    return this.data != null ? this.getOrderTypeText(this.data.orderType) : '';
  }

  getOrderTypeText(orderType: EnumOrderType): string {
    switch (orderType) {
      case EnumOrderType.PickUp:
        return 'Pickup';
      case EnumOrderType.DineIn:
        return 'Dine-in';
      case EnumOrderType.Delivery:
        return 'Delivery';
      default:
        return '';
    }
  }

  updateOrder() {
    this.data.patchValue(this.generalForm.value);


    if (this.data.isAsap) {
      this.data.orderDate = null;
    } else {
      // this.cartLocal.orderDate =
    }
    if (!this.data.isAsap) {
      if (this.generalForm.controls['selectedTime'].value != null)
        this.data.orderDate = fromUnixTime(this.generalForm.controls['selectedTime'].value);
    } else {
      this.data.orderDate = null;
    }

    this.cartSvc.save(this.data);
    // if (isStringNotEmptyGuid(this.data) && this.data.items.length > 0)
    //   this.cartSvc.validate(this.data);

    this.dialogRef.close(true);
  }

  isValidMinute(hourMinutes: Date) {
    return isAfter(hourMinutes, this.dtNow);
  }

  changeDeliveryTypeOld(evt: MatButtonToggleChange) {
    this.data.orderType = evt.value;
    this.data.orderDate = null;
    this.selectedTime = null;
    this.selectedDate = null;
    this.processSelectedDate();
  }

  changeDeliveryType(evt: MatButtonToggleChange) {
    this.generalForm.controls['selectedTime'].setValue(null);
    this.generalForm.controls['selectedDate'].setValue(null);
    this.processSelectedDate();
  }

  isOpen() {
    if (this.location != null) {
      switch (this.data.orderType) {
        case EnumOrderType.PickUp:
          return this.location.isOpen(EnumWebsiteModules.OnlineOrder, this.dtNow);
        case EnumOrderType.Delivery:
          return this.location.isOpen(EnumWebsiteModules.Delivery, this.dtNow);
        case EnumOrderType.DineIn:
          return this.location.isOpen(EnumWebsiteModules.DineIn, this.dtNow);
      }
    }
    return false;
  }

  selectAddress(evt: MatAutocompleteSelectedEvent) {
    this.data.selectedAddressId = '';

    this.isLoadingAddress = true;
    this.addressSvc.selectPlace(evt.option.value, this.data).subscribe(
      (fData: OrderGenericResponseModel) => {
        this.isLoadingAddress = false;
        if (fData.data != null)
          this.generalForm.controls['selectedAddressId'].patchValue(fData.data.selectedAddressId);
      }
    );
    this.generalForm.controls['addressHint'].setValue('');
    this.hintSubject.next('');
  }

  addHint(evt: any) {
    this.hintSubject.next(evt);
  }

  updateAuth(evtValue: boolean) {
    this.generalForm.controls['isGuestUser'].updateValueAndValidity();
  }

  editAddress(adr: OrderUserAddressModel) {
    const dialogRef = this.modal.open(AddressDialogComponent, {
      data: adr,
      minWidth: '400px',
      autoFocus: 'first-heading'
    });

    dialogRef.afterClosed().subscribe(data => {

    })
  }

  private processSelectedDate() {
    if (this.location != null && this.data != null) {
      let dtSelected: Date = this.data.orderDate ?? new Date();

      this.selectedDate = this.location.getFirstAvailableDate(dtSelected, this.data.orderType);
      if (this.selectedDate != null && (isBefore(dtSelected, this.selectedDate.times[0])))
        dtSelected = this.selectedDate.times[0];
      this.selectedTime = getUnixTime(dtSelected);

      this.generalForm.controls['selectedDate'].setValue(this.selectedDate);
      this.generalForm.controls['selectedTime'].setValue(this.selectedTime);

      const dtLead = addMinutes(new Date(), this.location.leadTimeForOrders);
      // this.ref.detectChanges();
      if (!this.location.isOpen(EnumWebsiteModules.OnlineOrder, dtLead) || !this.location.allowOrderNow)
        this.data.isAsap = false;
    }
  }
}
