import {Component, Inject} from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef, MatSnackBarLabel, MatSnackBarActions, MatSnackBarAction } from "@angular/material/snack-bar";
import {SnackBarData} from "../../models/OrderCartModelExtended";
import { NgFor } from '@angular/common';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-custom-snack-bar',
    templateUrl: 'custom-snack-bar.component.html',
    styleUrl: 'custom-snack-bar.component.scss',
    standalone: true,
    imports: [MatSnackBarLabel, NgFor, MatSnackBarActions, MatButton, MatSnackBarAction]
})
export class CustomSnackBarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData, public snackRef: MatSnackBarRef<CustomSnackBarComponent>) {
  }

  close() {
    this.snackRef.dismiss();
  }
}
