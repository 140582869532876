import {Component, Input} from '@angular/core';
import {ProductModifierItemOrderModel} from "../../models/ProductModifierItemOrderModel";
import {ProductModifierGroupOrderModel} from "../../models/ProductModifierGroupOrderModel";
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-modifier-item-quantity',
    templateUrl: './modifier-item-quantity.component.html',
    styleUrls: ['./modifier-item-quantity.component.scss'],
    standalone: true,
    imports: [MatIconButton, MatIcon]
})
export class ModifierItemQuantityComponent {
  @Input() modifierItem!: ProductModifierItemOrderModel;
  @Input() group!: ProductModifierGroupOrderModel;
  @Input() parentQuantity: number=1;

  constructor() {
  }

  public incQuantity() {
    this.modifierItem.quantity++;
  }

  public decQuantity() {
    if (this.modifierItem.quantity > 0)
      this.modifierItem.quantity--;
    if (this.modifierItem.quantity == 0)
      this.modifierItem.selected = false;
  }
}
